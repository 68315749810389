$color_1: #fff;
$color_2: #333;
$color_3: #172c54;
$color_4: #000;
$font-family_1: 'Font Awesome\ 5 Free';
$background-color_1: #172c54;
$background-color_2: #fff;
$background-color_3: #eee;
$border-bottom-color_1: #172c54;

.nav-tabs {
	.nav-link.active {
		color: $color_1 !important;
		background-color: $background-color_1 !important;
	}
	.nav-item.show {
		.nav-link {
			color: $color_1;
			background-color: $background-color_1 !important;
		}
	}
}
.events-list {
	background: #fff !important;
	border: 0 !important;
	border-radius: 0 !important;
	text-align: left;
	width: 100%;
	margin: 0 !important;
	padding: 0 !important;
	&:hover {
		cursor: inherit;
		background-color: $background-color_2 !important;
	}
	div {
		width: 100%;
		margin: 0;
	}
	ul {
		li {
			text-align: left;
			color: $color_2;
			list-style: none;
		}
		li.underlined {
			text-decoration: underline;
		}
		padding: 0 10px;
	}
	h6 {
		padding: 0 10px;
        text-align: left;
		font-size: 12px;
	}
}
.event-seperator {
	color: $color_2;
}
.fc {
	.fc-daygrid-day.fc-day-today {
		background-color: $background-color_2 !important;
	}
}
.progress-bar-thin {
	background-color: $background-color_3;
	height: 7px;
	border-radius: 25px;
	margin-bottom: 5px !important;
	position: relative;
    cursor: pointer;
	div.progress-tooltip {
		visibility: hidden;
		position: absolute;
	}
	&:hover {
		.progress-tooltip {
			visibility: visible;
			background: #ccc;
			width: auto;
			padding: 2px 8px;
			left: 4px;
			color: #000;
			position: absolute;
			height: auto;
			z-index: 9999;
			top: 16px;
			&:after {
				content: '';
				display: block;
				width: 0;
				height: 0;
				position: absolute;
				border-right: 8px solid transparent;
				border-top: 8px solid transparent;
				border-left: 8px solid transparent;
				border-bottom: 8px solid #ccc;
				top: -15px;
			}
		}
	}
	div{ height: 100%;
	border-radius: 25px;
	position: relative;
	div {
		color: $color_1;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	
}
} 

.headers {
    padding: 10px;
    color: $color_1;
}

.alert-accordion {
	.collapse {
		display: block !important;
		transition: all 5s ease-in-out !important;
	}
	.collapse.show {
		display: block !important;
		transition: all 5s ease-in-out !important;
	}
	.btn-link {
		&:focus {
			outline: 0;
			text-decoration: none;
			box-shadow: none;
		}
		&:hover {
			outline: 0;
			text-decoration: none;
			box-shadow: none;
		}
	}
	.card-header {
		&:first-child {
			border-radius: 0;
		}
		background: #172C54;
		padding: 4px 8px;
		cursor: pointer;
		.btn-link {
			color: $color_1;
			font-weight: 300;
			width: 100%;
			text-align: left;
			text-transform: capitalize;
			font-size: 17px;
			&:after {
				content: '\f106';
				font-family: $font-family_1;
				float: right;
				font-weight: 900;
			}
		}
		.btn-link.collapsed {
			&:after {
				content: '\f107';
				font-family: $font-family_1;
				float: right;
				font-weight: 900;
			}
		}
	}
}
.no-padding {
	padding: 0;
}
.alerts-list {
	.card-header {
		h5 {
			color: $color_3 !important;
			font-size: 13px;
			display: inline-block;
			margin: 0 !important;
			font-weight: 800;
			line-height: 12px;
			z-index: 99;
		}
		i {
			margin-left: 8px;
			color: $color_3;
			float: right;
			margin-top: 3px;
			margin-left: 8px;
			color: $color_3;
		}
		background: #fff !important;
		padding: 10px 10px;
		border-radius: 0;
		border-bottom-color: $border-bottom-color_1;
	}
	.card-body {
		padding: 0;
		background: #fff;
		border: 0;
		border-radius: 0;
	}
	.collapseContent {
		padding: 10px;
	}
	.card {
		width: 19.3%;
		display: inline-block;
		margin-right: 10px;
		border: 1px solid #172c54;
		border-radius: 0;
		vertical-align: text-top;
		&:nth-child(5n) {
			margin-right: 0;
		}
	}
}
.list-inline {
	li {
		float: left;
		margin-right: 30px;
		div.legend {
			display: inline-block;
			margin-top: 20px;
			div {
				width: 10px;
				height: 10px;
				margin-right: 5px;
				position: relative;
				top: 5px;
				float: left;
			}
			span {
				float: left;
				text-transform: capitalize;
				font-size: 12px;
			}
		}
	}
}

.progress-bar-custom {
	position: relative;
	background-color: $background-color_3;
	div { color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover {
		.progress-tooltip {
			&:after {
				content: '';
				display: block;
				width: 0;
				height: 0;
				position: absolute;
				border-right: 8px solid transparent;
				border-top: 8px solid transparent;
				border-left: 8px solid transparent;
				border-bottom: 8px solid #ccc;
				top: -15px;
			}
		}
	}
}
div.progress-tooltip {
	visibility: hidden;
	position: absolute;
}
} 
.progress-bar-custom {
    &:hover {
        .progress-tooltip {
            visibility: visible;
            background: #ccc;
            width: auto;
            padding: 2px 8px;
            bottom: -30px;
            z-index: 999;
            left: 42px;
            color: $color_4;
        }
    }
}

.flex{
    display: flex;
}

.p-datatable .p-datatable-tbody > tr > td{
    font-size: 13px;
    border-right: 1px solid #eee !important;
}

.p-datatable .p-datatable-thead > tr > th{
    font-size: 15px;
    border-right: 1px solid #eee !important;
}

.p-datatable .p-datatable-tbody > tr > td:last-child,
.p-datatable .p-datatable-thead > tr > th:last-child
    {
    border-right: 0 !important;
}

.no-margin {
    margin: 0;
}

.no-padding{
	padding: 0;
}

.mr-2 {
    margin-right: 5px;
}