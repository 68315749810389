body[data-layout-mode="dark"] {

  .apexcharts-gridline {
    stroke: lighten($gray-dark-200, 2.5%);
  }

  .apexcharts-tooltip.apexcharts-theme-light {
    background-color: $gray-dark-200;
    border-color: lighten($gray-dark-200, 2.5%);

    .apexcharts-tooltip-title {
      background-color: $gray-dark-300;
      border-color: lighten($gray-dark-300, 2.5%);
    }
  }

  .apexcharts-yaxis-texts-g.apexcharts-xaxis-inversed-texts-g,
  .apexcharts-grid-row {
    +line {
      stroke: lighten($gray-dark-300, 2.5%);
    }
  }

  .apexcharts-xaxis line,
  .apexcharts-treemap-rect,
  .apexcharts-heatmap-rect,
  .apexcharts-pie-area {
    stroke: lighten($gray-dark-300, 2.5%);
  }

  .apexcharts-radialbar-track.apexcharts-track {
    path {
      stroke: lighten($gray-dark-300, 2.5%);
    }
  }

  .apexcharts-radar-series.apexcharts-plot-series {

    polygon,
    line {
      fill: $gray-dark-300;
      stroke: lighten($gray-dark-300, 2.5%);
    }
  }

  .apexcharts-pie {
    circle {
      stroke: lighten($gray-dark-300, 2.5%);
    }
  }


  // CK-Editor
  .ck.ck-toolbar {
    background-color: $gray-dark-200 !important;
    border: 1px solid $gray-dark-300 !important;
  }

  .ck.ck-editor__main>.ck-editor__editable {
    border-color: $gray-dark-300 !important;
    box-shadow: none;
  }

  .ck.ck-editor__main>.ck-editor__editable {
    background: $gray-dark-200 !important;
  }

  .ck.ck-reset_all,
  .ck.ck-reset_all * {
    color: $gray-dark-600;
  }

  .ck.ck-toolbar .ck.ck-toolbar__separator,
  .mce-container {
    background: transparent;
  }

  .ck.ck-button.ck-on,
  a.ck.ck-button.ck-on,
  .ck.ck-button:not(.ck-disabled):hover,
  a.ck.ck-button:not(.ck-disabled):hover {
    background: $gray-dark-600;
  }


  // SweetAlert

  .swal2-popup {
    background-color: $gray-dark-300 !important;
  }

  .swal2-content {
    color: $gray-dark-500;
  }

  // toast
  .toast {
    background-color: rgba($gray-dark-300, .85);
    border: 1px solid rgba($white, .1);
  }

  .toast-header {
    background-color: rgba($gray-dark-300, .85);
    color: $gray-dark-400;
  }

  // dropzone
  ngx-dropzone {
    background: $gray-dark-200;
  }

  // wizard
  .ng-wizard-theme-default .step-content {
    background-color: $gray-dark-200;
  }

  .ng-wizard-theme-default .ng-wizard-toolbar {
    background: $gray-dark-200;
  }

  // Select2

  .select2-container {
    .select2-selection {
      background-color: lighten($gray-dark-200, 2.5%) !important;
      border: 1px solid $gray-dark-300 !important;

      .select2-selection__rendered {
        color: $gray-dark-500 !important;
      }

      .select2-search__field {
        color: $gray-dark-500 !important;

        &::placeholder {
          color: $gray-dark-400 !important;
        }
      }

      .select2-selection__placeholder {
        color: $gray-dark-400;
      }

      .select2-selection__choice {
        background-color: lighten($gray-dark-300, 2.5%);
        border: 1px solid $gray-dark-300;
      }
    }

    .select2-results__option[aria-selected=true] {
      background-color: $gray-dark-300 !important;
      color: $gray-dark-500 !important;
    }

    .select2-dropdown {
      background-color: lighten($gray-dark-200, 2.5%);
      border: 1px solid $gray-dark-300;
    }

    .select2-search--dropdown {
      background-color: lighten($gray-dark-200, 2.5%);

      .select2-search__field {
        background-color: lighten($gray-dark-200, 2.5%);
        border: 1px solid $gray-dark-300;
        color: $gray-dark-500 !important;
      }
    }

    .select2-label-content {
      color: $gray-dark-500;
    }
  }


  // color picker
  .sp-container {
    background-color: lighten($gray-dark-200, 2.5%);

    button {
      color: $gray-dark-500;

      &.sp-choose {
        color: $white;
      }

      &.sp-palette-toggle {
        background-color: $gray-dark-300;
      }
    }
  }

  .sp-palette-container {
    border-right: 1px solid $gray-dark-300;
  }

  // datepicker

  .datepicker {
    border-color: $gray-dark-300;

    table {
      tr {
        td {

          span,
          &.day {

            &.focused,
            &:hover {
              background-color: $gray-dark-300;
            }
          }

          &.range {
            background-color: $gray-dark-300;

            &.disabled,
            &.disabled:hover,
            &:hover {
              background-color: $gray-dark-300;
            }
          }
        }
      }
    }

    .datepicker-switch,
    .next,
    .prev,
    tfoot tr th {
      &:hover {
        background-color: $gray-dark-300;
      }
    }
  }

  // tinymce editor
  .tox-tinymce {
    border-color: $gray-dark-300 !important;
  }

  .tox {
    color: $gray-dark-500 !important;

    .tox-mbtn,
    .tox-tbtn {
      color: $gray-dark-500 !important;

      svg {
        fill: $gray-dark-500 !important;
      }

      &:hover,
      &:focus {
        background: $gray-dark-300 !important;
      }
    }

    .tox-tbtn--enabled,
    .tox-tbtn--enabled:hover {
      background: $gray-dark-300 !important;
    }

    .tox-toolbar,
    .tox-toolbar__overflow,
    .tox-toolbar__primary {
      border-color: lighten($gray-dark-300, 2%) !important;
      background: $gray-dark-300 !important;
    }

    &:not([dir=rtl]) {
      .tox-toolbar__group:not(:last-of-type) {
        border-color: lighten($gray-dark-300, 2%) !important;
      }
    }

    .tox-mbtn:hover:not(:disabled):not(.tox-mbtn--active),
    .tox-mbtn--active {
      background: $gray-dark-300 !important;
    }

    .tox-split-button {
      box-shadow: none !important;

      &:focus {
        background: $gray-dark-300 !important;
      }
    }

    .tox-statusbar {
      border-color: lighten($gray-dark-300, 2%) !important;
      color: $gray-dark-500 !important;
    }

    .tox-statusbar a,
    .tox-statusbar__path-item,
    .tox-statusbar__wordcount {
      color: $gray-dark-500 !important;
    }
  }

  .tox .tox-edit-area__iframe,
  .tox .tox-menubar,
  .tox .tox-statusbar {
    background: $gray-dark-200 !important;
  }


  // dropzone
  .dropzone {
    background-color: lighten($gray-dark-200, 2.5%);
    border-color: $gray-dark-300;
  }

  // Wizard

  .wizard {
    .steps>ul>li {
      a {
        color: $gray-dark-500 !important;
      }
    }
  }

  // datatable

  .activate-select .sorting_1 {
    background-color: $gray-dark-200;
  }

  .dt-button-collection {
    .dt-button {
      &.active {
        background-color: $gray-dark-300;
      }
    }
  }

  // Google Maps
  .gmaps,
  .gmaps-panaroma {
    background: $gray-dark-300;
  }

  .fc-theme-standard th,
  .fc-theme-standard td,
  .fc-theme-standard .fc-scrollgrid {
    border: 1px solid $gray-dark-300;
  }


  // .select2-container--default .select2-selection--single {
  //   background: $gray-dark-300 !important;
  // }
}
