//
// _helper.scss
//

.font-size-10 {
    font-size: 10px !important;
  }
  
  .font-size-11 {
    font-size: 11px !important;
  }
  
  .font-size-12 {
    font-size: 12px !important;
  }
  
  .font-size-13 {
    font-size: 13px !important;
  }
  
  .font-size-14 {
    font-size: 14px !important;
  }
  
  .font-size-15 {
    font-size: 15px !important;
  }
  
  .font-size-16 {
    font-size: 16px !important;
  }
  
  .font-size-17 {
    font-size: 17px !important;
  }
  
  .font-size-18 {
    font-size: 18px !important;
  }
  
  .font-size-20 {
    font-size: 20px !important;
  }
  
  .font-size-22 {
    font-size: 22px !important;
  }
  
  .font-size-24 {
    font-size: 24px !important;
  }
  
  // Font weight help class
  
  .fw-medium {
    font-weight: $font-weight-medium;
  }
  
  .fw-semibold {
    font-weight: $font-weight-semibold !important;
  }
  
  // Flex
  
  .flex-1 {
    flex: 1;
  }
  
  
  // Social
  
  .social-list-item {
    height: 2rem;
    width: 2rem;
    line-height: calc(2rem - 4px);
    display: block;
    border: 2px solid $gray-500;
    border-radius: 50%;
    color: $gray-500;
    text-align: center;
    transition: all 0.4s;
  
    &:hover {
      color: $gray-600;
      background-color: $gray-200;
    }
  }
  
  
  // Backgroun pattern
  .bg-pattern {
    background: url("../../../images/modal-bg.png") $modal-content-bg;
  }
  
  
  .w-xs {
    min-width: 80px;
  }
  
  .w-sm {
    min-width: 95px;
  }
  
  .w-md {
    min-width: 110px;
  }
  
  .w-lg {
    min-width: 140px;
  }
  
  .w-xl {
    min-width: 160px;
  }
  
  // alert
  
  .alert-dismissible {
    .btn-close {
      font-size: 10px;
      padding: $alert-padding-y * 1.4 $alert-padding-x;
    }
  }
  
  
  // overlay
  
  .bg-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    opacity: 0.7;
    background-color: $black;
  }
  
  // alert
  
  .alert-dismissible {
    .btn-close {
      font-size: 10px;
      padding: $alert-padding-y * 1.4 $alert-padding-x;
      background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
    }
  }
  
  .custom-alert {
    .close {
      background: transparent;
      border: 0;
      font-size: 10px;
      padding: 1.35rem 1.25rem;
      background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
      position: absolute;
      top: 0;
      right: 0;
      opacity: .5;
      width: 1em;
      height: 1em;
      z-index: 2;
  
      span {
        display: none;
      }
    }
  }
  